@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
:root {
  --ncl-font-family-primary:Montserrat, Helvetica, sans-serif;
  --ncl-font-family-secondary:IBM Plex Sans, sans-serif;
  --ncl-font-family-tertiary:EB Garamond, serif;
  --ncl-icon-font-family:FCA Icons;
  --ncl-color-neutral:#fff;
  --ncl-color-neutral-10:#f2f2f2;
  --ncl-color-neutral-20:#dcdcdc;
  --ncl-color-neutral-30:#c5c5c5;
  --ncl-color-neutral-40:#636363;
  --ncl-color-neutral-50:#757575;
  --ncl-color-neutral-55:#808080;
  --ncl-color-neutral-60:#1b1b1b;
  --ncl-color-neutral-70:#666666;
  --ncl-color-neutral-80:#001F49;
  --ncl-color-dark:#000;
  --ncl-color-dark-5:#262626;
  --ncl-color-dark-10:#7a7a7a;
  --ncl-color-dark-20:#d7d6d6;
  --ncl-color-dark-30:#eeeef1;
  --ncl-color-dark-50:#dfdfdf;
  --ncl-color-dark-60:#e6e6e6;
  --ncl-color-dark-70:#f2f2f2;
  --ncl-color-dark-80:#f6f6f6;
  --ncl-color-primary:#1e4544;
  --ncl-color-primary-light:#1e4544;
  --ncl-color-primary-dark:#0f2623;
  --ncl-color-highlight:#1c72f2;
  --ncl-color-secondary:#dfdfdf;
  --ncl-color-tertiary:blue;
  --ncl-color-neutral-background:#f6f6f6;
  --ncl-color-danger:#cc0000;
  --ncl-color-danger-10:#fff6f6;
  --ncl-color-warning:#ffba00;
  --ncl-color-success:green;
  --ncl-color-info:blue;
  --ncl-color-disabled:#aaaaaa;
  --ncl-color-active:#fff;
  --ncl-color-focus:#1c72f2;
  --ncl-font-color-primary:#000;
  --ncl-font-color-secondary:#000;
  --ncl-font-color-tertiary:#fff;
  --ncl-font-weight-extra-bold:800;
  --ncl-font-weight-bold:700;
  --ncl-font-weight-semi-bold:600;
  --ncl-font-weight-medium:500;
  --ncl-font-weight-regular:400;
  --ncl-font-weight-light:300;
  --ncl-font-weight-extra-light:200;
  --ncl-font-weight-thin:100;
  --ncl-letter-spacing-base:normal;
  --ncl-line-height-base:1;
  --ncl-line-height-xl:2;
  --ncl-line-height-l:1.75;
  --ncl-line-height-m:1.5;
  --ncl-line-height-s:1.25;
  --ncl-line-height-xs:0.75;
  --ncl-text-decoration-style:solid;
  --ncl-text-underline-offset:auto;
  --ncl-box-shadow-none:none;
  --ncl-border-radius-circle:50%;
  --ncl-transition-fastest:200ms;
  --ncl-transition-faster:300ms;
  --ncl-transition-fast:400ms;
  --ncl-transition-slow:500ms;
  --ncl-transition-slower:600ms;
  --ncl-transition-slowest:700ms;
  --ncl-transition-effect:cubic-bezier(0.25, 1, 0.5, 1);
  --ncl-z-index-deepest:-999999;
  --ncl-z-index-deeper:-999;
  --ncl-z-index-deep:-1;
  --ncl-z-index-default:1;
  --ncl-z-index-high:10;
  --ncl-z-index-higher:999;
  --ncl-z-index-highest:999999;
  --ncl-font-size-base:1.6rem;
  --ncl-font-size-4xl:4rem;
  --ncl-font-size-3xl:3rem;
  --ncl-font-size-2xl:2.4rem;
  --ncl-font-size-xl:1.8rem;
  --ncl-font-size-l:1.6rem;
  --ncl-font-size-m:1.4rem;
  --ncl-font-size-s:1.3rem;
  --ncl-font-size-xs:1rem;
  --ncl-font-size-2xs:0.8rem;
  --ncl-letter-spacing-s:-0.001rem;
  --ncl-text-decoration-thickness:0.1rem;
  --ncl-border-radius-sharp:0rem;
  --ncl-border-radius-base:0.4rem;
  --ncl-border-radius-pill:32rem;
  --ncl-focus-outline-size:0.2rem;
  --ncl-focus-outline-offset:0.4rem;
  --ncl-spacing-0-5:0.05rem;
  --ncl-spacing-0:0rem;
  --ncl-spacing-1:0.1rem;
  --ncl-spacing-2:0.2rem;
  --ncl-spacing-3:0.3rem;
  --ncl-spacing-4:0.4rem;
  --ncl-spacing-5:0.5rem;
  --ncl-spacing-6:0.6rem;
  --ncl-spacing-7:0.7rem;
  --ncl-spacing-8:0.8rem;
  --ncl-spacing-9:0.9rem;
  --ncl-spacing-10:1rem;
  --ncl-spacing-11:1.1rem;
  --ncl-spacing-12:1.2rem;
  --ncl-spacing-13:1.3rem;
  --ncl-spacing-14:1.4rem;
  --ncl-spacing-15:1.5rem;
  --ncl-spacing-16:1.6rem;
  --ncl-spacing-17:1.7rem;
  --ncl-spacing-18:1.8rem;
  --ncl-spacing-19:1.9rem;
  --ncl-spacing-20:2rem;
  --ncl-spacing-21:2.1rem;
  --ncl-spacing-22:2.2rem;
  --ncl-spacing-23:2.3rem;
  --ncl-spacing-24:2.4rem;
  --ncl-spacing-25:2.5rem;
  --ncl-spacing-26:2.6rem;
  --ncl-spacing-27:2.7rem;
  --ncl-spacing-28:2.8rem;
  --ncl-spacing-29:2.9rem;
  --ncl-spacing-30:3rem;
  --ncl-spacing-31:3.1rem;
  --ncl-spacing-32:3.2rem;
  --ncl-spacing-33:3.3rem;
  --ncl-spacing-34:3.4rem;
  --ncl-spacing-35:3.5rem;
  --ncl-spacing-36:3.6rem;
  --ncl-spacing-37:3.7rem;
  --ncl-spacing-38:3.8rem;
  --ncl-spacing-39:3.9rem;
  --ncl-spacing-40:4rem;
  --ncl-spacing-41:4.1rem;
  --ncl-spacing-42:4.2rem;
  --ncl-spacing-43:4.3rem;
  --ncl-spacing-44:4.4rem;
  --ncl-spacing-45:4.5rem;
  --ncl-spacing-46:4.6rem;
  --ncl-spacing-47:4.7rem;
  --ncl-spacing-48:4.8rem;
  --ncl-spacing-49:4.9rem;
  --ncl-spacing-50:5rem;
  --ncl-spacing-51:5.1rem;
  --ncl-spacing-52:5.2rem;
  --ncl-spacing-53:5.3rem;
  --ncl-spacing-54:5.4rem;
  --ncl-spacing-55:5.5rem;
  --ncl-spacing-56:5.6rem;
  --ncl-spacing-57:5.7rem;
  --ncl-spacing-58:5.8rem;
  --ncl-spacing-59:5.9rem;
  --ncl-spacing-60:6rem;
  --ncl-spacing-61:6.1rem;
  --ncl-spacing-62:6.2rem;
  --ncl-spacing-63:6.3rem;
  --ncl-spacing-64:6.4rem;
  --ncl-spacing-65:6.5rem;
  --ncl-spacing-66:6.6rem;
  --ncl-spacing-67:6.7rem;
  --ncl-spacing-68:6.8rem;
  --ncl-spacing-69:6.9rem;
  --ncl-spacing-70:7rem;
  --ncl-spacing-71:7.1rem;
  --ncl-spacing-72:7.2rem;
  --ncl-spacing-73:7.3rem;
  --ncl-spacing-74:7.4rem;
  --ncl-spacing-75:7.5rem;
  --ncl-spacing-76:7.6rem;
  --ncl-spacing-77:7.7rem;
  --ncl-spacing-78:7.8rem;
  --ncl-spacing-79:7.9rem;
  --ncl-spacing-80:8rem;
  --ncl-spacing-81:8.1rem;
  --ncl-spacing-82:8.2rem;
  --ncl-spacing-83:8.3rem;
  --ncl-spacing-84:8.4rem;
  --ncl-spacing-85:8.5rem;
  --ncl-spacing-86:8.6rem;
  --ncl-spacing-87:8.7rem;
  --ncl-spacing-88:8.8rem;
  --ncl-spacing-89:8.9rem;
  --ncl-spacing-90:9rem;
  --ncl-spacing-91:9.1rem;
  --ncl-spacing-92:9.2rem;
  --ncl-spacing-93:9.3rem;
  --ncl-spacing-94:9.4rem;
  --ncl-spacing-95:9.5rem;
  --ncl-spacing-96:9.6rem;
  --ncl-spacing-97:9.7rem;
  --ncl-spacing-98:9.8rem;
  --ncl-spacing-99:9.9rem;
  --ncl-spacing-100:10rem;
  --ncl-spacing-101:10.1rem;
  --ncl-spacing-102:10.2rem;
  --ncl-spacing-103:10.3rem;
  --ncl-spacing-104:10.4rem;
  --ncl-spacing-105:10.5rem;
  --ncl-spacing-106:10.6rem;
  --ncl-spacing-107:10.7rem;
  --ncl-spacing-108:10.8rem;
  --ncl-spacing-109:10.9rem;
  --ncl-spacing-110:11rem;
  --ncl-spacing-111:11.1rem;
  --ncl-spacing-112:11.2rem;
  --ncl-spacing-113:11.3rem;
  --ncl-spacing-114:11.4rem;
  --ncl-spacing-115:11.5rem;
  --ncl-spacing-116:11.6rem;
  --ncl-spacing-117:11.7rem;
  --ncl-spacing-118:11.8rem;
  --ncl-spacing-119:11.9rem;
  --ncl-spacing-120:12rem;
  --ncl-spacing-121:12.1rem;
  --ncl-spacing-122:12.2rem;
  --ncl-spacing-123:12.3rem;
  --ncl-spacing-124:12.4rem;
  --ncl-spacing-125:12.5rem;
  --ncl-spacing-126:12.6rem;
  --ncl-spacing-127:12.7rem;
  --ncl-spacing-128:12.8rem;
  --ncl-spacing-129:12.9rem;
  --ncl-spacing-130:13rem;
  --ncl-spacing-131:13.1rem;
  --ncl-spacing-132:13.2rem;
  --ncl-spacing-133:13.3rem;
  --ncl-spacing-134:13.4rem;
  --ncl-spacing-135:13.5rem;
  --ncl-spacing-136:13.6rem;
  --ncl-spacing-137:13.7rem;
  --ncl-spacing-138:13.8rem;
  --ncl-spacing-139:13.9rem;
  --ncl-spacing-140:14rem;
  --ncl-spacing-141:14.1rem;
  --ncl-spacing-142:14.2rem;
  --ncl-spacing-143:14.3rem;
  --ncl-spacing-144:14.4rem;
  --ncl-spacing-145:14.5rem;
  --ncl-spacing-146:14.6rem;
  --ncl-spacing-147:14.7rem;
  --ncl-spacing-148:14.8rem;
  --ncl-spacing-149:14.9rem;
  --ncl-spacing-150:15rem;
  --ncl-spacing-151:15.1rem;
  --ncl-spacing-152:15.2rem;
  --ncl-spacing-153:15.3rem;
  --ncl-spacing-154:15.4rem;
  --ncl-spacing-155:15.5rem;
  --ncl-spacing-156:15.6rem;
  --ncl-spacing-157:15.7rem;
  --ncl-spacing-158:15.8rem;
  --ncl-spacing-159:15.9rem;
  --ncl-spacing-160:16rem;
  --ncl-spacing-161:16.1rem;
  --ncl-spacing-162:16.2rem;
  --ncl-spacing-163:16.3rem;
  --ncl-spacing-164:16.4rem;
  --ncl-spacing-165:16.5rem;
  --ncl-spacing-166:16.6rem;
  --ncl-spacing-167:16.7rem;
  --ncl-spacing-168:16.8rem;
  --ncl-spacing-169:16.9rem;
  --ncl-spacing-170:17rem;
  --ncl-spacing-171:17.1rem;
  --ncl-spacing-172:17.2rem;
  --ncl-spacing-173:17.3rem;
  --ncl-spacing-174:17.4rem;
  --ncl-spacing-175:17.5rem;
  --ncl-spacing-176:17.6rem;
  --ncl-spacing-177:17.7rem;
  --ncl-spacing-178:17.8rem;
  --ncl-spacing-179:17.9rem;
  --ncl-spacing-180:18rem;
  --ncl-spacing-181:18.1rem;
  --ncl-spacing-182:18.2rem;
  --ncl-spacing-183:18.3rem;
  --ncl-spacing-184:18.4rem;
  --ncl-spacing-185:18.5rem;
  --ncl-spacing-186:18.6rem;
  --ncl-spacing-187:18.7rem;
  --ncl-spacing-188:18.8rem;
  --ncl-spacing-189:18.9rem;
  --ncl-spacing-190:19rem;
  --ncl-spacing-191:19.1rem;
  --ncl-spacing-192:19.2rem;
  --ncl-spacing-193:19.3rem;
  --ncl-spacing-194:19.4rem;
  --ncl-spacing-195:19.5rem;
  --ncl-spacing-196:19.6rem;
  --ncl-spacing-197:19.7rem;
  --ncl-spacing-198:19.8rem;
  --ncl-spacing-199:19.9rem;
  --ncl-spacing-200:20rem;
  --ncl-spacing-201:20.1rem;
  --ncl-spacing-202:20.2rem;
  --ncl-spacing-203:20.3rem;
  --ncl-spacing-204:20.4rem;
  --ncl-spacing-205:20.5rem;
  --ncl-spacing-206:20.6rem;
  --ncl-spacing-207:20.7rem;
  --ncl-spacing-208:20.8rem;
  --ncl-spacing-209:20.9rem;
  --ncl-spacing-210:21rem;
  --ncl-spacing-211:21.1rem;
  --ncl-spacing-212:21.2rem;
  --ncl-spacing-213:21.3rem;
  --ncl-spacing-214:21.4rem;
  --ncl-spacing-215:21.5rem;
  --ncl-spacing-216:21.6rem;
  --ncl-spacing-217:21.7rem;
  --ncl-spacing-218:21.8rem;
  --ncl-spacing-219:21.9rem;
  --ncl-spacing-220:22rem;
  --ncl-spacing-221:22.1rem;
  --ncl-spacing-222:22.2rem;
  --ncl-spacing-223:22.3rem;
  --ncl-spacing-224:22.4rem;
  --ncl-spacing-225:22.5rem;
  --ncl-spacing-226:22.6rem;
  --ncl-spacing-227:22.7rem;
  --ncl-spacing-228:22.8rem;
  --ncl-spacing-229:22.9rem;
  --ncl-spacing-230:23rem;
  --ncl-spacing-231:23.1rem;
  --ncl-spacing-232:23.2rem;
  --ncl-spacing-233:23.3rem;
  --ncl-spacing-234:23.4rem;
  --ncl-spacing-235:23.5rem;
  --ncl-spacing-236:23.6rem;
  --ncl-spacing-237:23.7rem;
  --ncl-spacing-238:23.8rem;
  --ncl-spacing-239:23.9rem;
  --ncl-spacing-240:24rem;
  --ncl-spacing-241:24.1rem;
  --ncl-spacing-242:24.2rem;
  --ncl-spacing-243:24.3rem;
  --ncl-spacing-244:24.4rem;
  --ncl-spacing-245:24.5rem;
  --ncl-spacing-246:24.6rem;
  --ncl-spacing-247:24.7rem;
  --ncl-spacing-248:24.8rem;
  --ncl-spacing-249:24.9rem;
  --ncl-spacing-250:25rem;
  --ncl-spacing-251:25.1rem;
  --ncl-spacing-252:25.2rem;
  --ncl-spacing-253:25.3rem;
  --ncl-spacing-254:25.4rem;
  --ncl-spacing-255:25.5rem;
  --ncl-spacing-256:25.6rem;
  --ncl-spacing-257:25.7rem;
  --ncl-spacing-258:25.8rem;
  --ncl-spacing-259:25.9rem;
  --ncl-spacing-260:26rem;
  --ncl-spacing-261:26.1rem;
  --ncl-spacing-262:26.2rem;
  --ncl-spacing-263:26.3rem;
  --ncl-spacing-264:26.4rem;
  --ncl-spacing-265:26.5rem;
  --ncl-spacing-266:26.6rem;
  --ncl-spacing-267:26.7rem;
  --ncl-spacing-268:26.8rem;
  --ncl-spacing-269:26.9rem;
  --ncl-spacing-270:27rem;
  --ncl-spacing-271:27.1rem;
  --ncl-spacing-272:27.2rem;
  --ncl-spacing-273:27.3rem;
  --ncl-spacing-274:27.4rem;
  --ncl-spacing-275:27.5rem;
  --ncl-spacing-276:27.6rem;
  --ncl-spacing-277:27.7rem;
  --ncl-spacing-278:27.8rem;
  --ncl-spacing-279:27.9rem;
  --ncl-spacing-280:28rem;
  --ncl-spacing-281:28.1rem;
  --ncl-spacing-282:28.2rem;
  --ncl-spacing-283:28.3rem;
  --ncl-spacing-284:28.4rem;
  --ncl-spacing-285:28.5rem;
  --ncl-spacing-286:28.6rem;
  --ncl-spacing-287:28.7rem;
  --ncl-spacing-288:28.8rem;
  --ncl-spacing-289:28.9rem;
  --ncl-spacing-290:29rem;
  --ncl-spacing-291:29.1rem;
  --ncl-spacing-292:29.2rem;
  --ncl-spacing-293:29.3rem;
  --ncl-spacing-294:29.4rem;
  --ncl-spacing-295:29.5rem;
  --ncl-spacing-296:29.6rem;
  --ncl-spacing-297:29.7rem;
  --ncl-spacing-298:29.8rem;
  --ncl-spacing-299:29.9rem;
  --ncl-spacing-300:30rem;
  --ncl-spacing-301:30.1rem;
  --ncl-spacing-302:30.2rem;
  --ncl-spacing-303:30.3rem;
  --ncl-spacing-304:30.4rem;
  --ncl-spacing-305:30.5rem;
  --ncl-spacing-306:30.6rem;
  --ncl-spacing-307:30.7rem;
  --ncl-spacing-308:30.8rem;
  --ncl-spacing-309:30.9rem;
  --ncl-spacing-310:31rem;
  --ncl-spacing-311:31.1rem;
  --ncl-spacing-312:31.2rem;
  --ncl-spacing-313:31.3rem;
  --ncl-spacing-314:31.4rem;
  --ncl-spacing-315:31.5rem;
  --ncl-spacing-316:31.6rem;
  --ncl-spacing-317:31.7rem;
  --ncl-spacing-318:31.8rem;
  --ncl-spacing-319:31.9rem;
  --ncl-spacing-320:32rem;
  --ncl-button-display:inline-block;
  --ncl-button-font-family:var(--ncl-font-family-primary);
  --ncl-button-font-color:var(--ncl-color-neutral);
  --ncl-button-padding-y:var(--ncl-spacing-16);
  --ncl-button-padding-x:var(--ncl-spacing-16);
  --ncl-button-font-weight:var(--ncl-font-weight-medium);
  --ncl-button-font-size:var(--ncl-font-size-base);
  --ncl-button-line-height:var(--ncl-line-height-base);
  --ncl-button-medium-min-height:var(--ncl-spacing-44);
  --ncl-button-medium-min-width:var(--ncl-spacing-200);
  --ncl-button-width:100%;
  --ncl-button-text-align:center;
  --ncl-button-suffix-padding:0 0 0 var(--ncl-spacing-12);
  --ncl-button-prefix-padding:0 var(--ncl-spacing-12) 0 0;
  --ncl-button-outline-width:var(--ncl-focus-outline-size);
  --ncl-button-outline-color:var(--ncl-color-focus);
  --ncl-button-outline-offset:var(--ncl-focus-outline-offset);
  --ncl-button-primary-background:var(--ncl-color-primary);
  --ncl-button-primary-background-hover:var(--ncl-color-primary-light);
  --ncl-button-primary-background-active:var(--ncl-color-primary-dark);
  --ncl-button-primary-background-focus:var(--ncl-color-primary-dark);
  --ncl-button-primary-border:var(--ncl-spacing-2) solid var(--ncl-color-primary);
  --ncl-button-primary-border-color-hover:var(--ncl-color-primary-light);
  --ncl-button-primary-border-color-active:var(--ncl-color-primary-dark);
  --ncl-button-primary-border-color-focus:var(--ncl-color-primary-dark);
  --ncl-button-primary-border-radius:var(--ncl-border-radius-base);
  --ncl-button-primary-font-color:var(--ncl-color-neutral);
  --ncl-button-primary-disabled-background:var(--ncl-color-neutral-50);
  --ncl-button-primary-disabled-border:var(--ncl-spacing-2) solid var(--ncl-color-neutral-50);
  --ncl-button-primary-disabled-color:var(--ncl-color-neutral);
  --ncl-button-primary-disabled-icon-color:var(--ncl-color-neutral);
  --ncl-button-disabled-background:var(--ncl-color-neutral-20);
  --ncl-button-disabled-border:var(--ncl-spacing-2) solid var(--ncl-color-neutral-20);
  --ncl-button-disabled-color:var(--ncl-color-disabled);
  --ncl-button-disabled-icon-color:var(--ncl-color-disabled);
  --ncl-button-outline-disabled-background:var(--ncl-color-neutral-10);
  --ncl-button-outline-disabled-color:var(--ncl-color-neutral-50);
  --ncl-button-outline-disabled-border:var(--ncl-spacing-2) solid var(--ncl-button-outline-disabled-color);
  --ncl-button-primary-outline-color:var(--ncl-color-dark);
  --ncl-button-primary-outline-color-hover:var(--ncl-color-dark);
  --ncl-button-primary-outline-color-active:var(--ncl-color-dark);
  --ncl-button-primary-outline-color-focus:var(--ncl-color-dark);
  --ncl-button-primary-color-focus:var(--ncl-color-dark);
  --ncl-button-primary-outline-background:transparent;
  --ncl-button-plain-disabled-background:transparent;
  --ncl-button-plain-disabled-border:var(--ncl-spacing-1) solid transparent;
  --ncl-button-plain-disabled-color:var(--ncl-color-disabled);
  --ncl-button-plain-disabled-icon-color:var(--ncl-color-disabled);
  --ncl-button-primary-plain-color:var(--ncl-color-dark);
  --ncl-button-secondary-background:var(--ncl-color-dark);
  --ncl-button-secondary-border:var(--ncl-spacing-1) solid var(--ncl-color-dark);
  --ncl-button-secondary-border-radius:var(--ncl-border-radius-base);
  --ncl-button-secondary-font-color:var(--ncl-color-neutral);
  --ncl-button-secondary-background-hover:var(--ncl-color-neutral-60);
  --ncl-button-secondary-border-color-hover:var(--ncl-color-neutral-60);
  --ncl-button-secondary-background-active:var(--ncl-color-neutral-60);
  --ncl-button-secondary-border-color-active:var(--ncl-color-neutral-60);
  --ncl-button-secondary-outline-color:var(--ncl-color-dark);
  --ncl-button-secondary-plain-color:var(--ncl-color-dark);
  --ncl-button-tertiary-font-color:var(--ncl-color-dark);
  --ncl-button-tertiary-disabled-color:var(--ncl-color-neutral-70);
  --ncl-button-rounded-border-radius:var(--ncl-border-radius-pill);
  --ncl-button-rounded-padding-y:var(--ncl-spacing-16);
  --ncl-button-rounded-padding-x:var(--ncl-spacing-40);
  --ncl-button-rounded-min-height:var(--ncl-spacing-50);
  --ncl-button-small-padding-y:var(--ncl-spacing-8);
  --ncl-button-small-padding-x:var(--ncl-spacing-16);
  --ncl-button-small-font-weight:var(--ncl-font-weight-medium);
  --ncl-button-small-font-size:var(--ncl-font-size-s);
  --ncl-button-small-min-height:var(--ncl-spacing-32);
  --ncl-button-large-padding-y:var(--ncl-spacing-20);
  --ncl-button-large-padding-x:var(--ncl-spacing-40);
  --ncl-button-large-font-weight:var(--ncl-font-weight-bold);
  --ncl-button-large-font-size:var(--ncl-font-size-l);
  --ncl-button-large-min-height:var(--ncl-spacing-58);
  --ncl-button-outline-background-hover:var(--ncl-color-neutral-10);
  --ncl-button-outline-color-hover:var(--ncl-color-dark);
  --ncl-button-outline-background-focus:transparent;
  --ncl-button-outline-color-focus:var(--ncl-color-dark);
  --ncl-button-outline-background-active:var(--ncl-color-neutral-20);
  --ncl-button-outline-color-active:var(--ncl-color-dark);
  --ncl-button-outline-font-color:var(--ncl-color-dark);
  --ncl-button-plain-padding:var(--ncl-spacing-0);
  --ncl-button-plain-underline-offset:var(--ncl-spacing-4);
  --ncl-button-plain-underline-color-hover:var(--ncl-color-neutral-30);
  --ncl-button-plain-underline-color-active:var(--ncl-color-neutral-40);
  --ncl-button-plain-focus-background-color:transparent;
  --ncl-button-inverted-background:var(--ncl-color-neutral);
  --ncl-button-inverted-border:var(--ncl-spacing-1) solid var(--ncl-color-neutral);
  --ncl-button-inverted-primary-color:var(--ncl-color-dark);
  --ncl-button-inverted-secondary-color:var(--ncl-color-dark);
  --ncl-button-inverted-background-hover:var(--ncl-color-neutral-10);
  --ncl-button-inverted-border-color-hover:var(--ncl-color-neutral-10);
  --ncl-button-inverted-background-active:var(--ncl-color-neutral-20);
  --ncl-button-inverted-border-color-active:var(--ncl-color-neutral-20);
  --ncl-button-inverted-outline-color:var(--ncl-color-neutral);
  --ncl-button-inverted-outline-background-hover:var(--ncl-color-neutral-60);
  --ncl-button-inverted-outline-border-color-hover:var(--ncl-color-neutral);
  --ncl-button-inverted-plain-color:var(--ncl-color-neutral);
  --ncl-button-icon-radius:var(--ncl-border-radius-circle);
  --ncl-button-icon-container-size:var(--ncl-spacing-32);
  --ncl-button-icon-padding:var(--ncl-spacing-7);
  --ncl-button-icon-icon-size:var(--ncl-font-size-m);
  --ncl-button-icon-container-size-small:var(--ncl-spacing-24);
  --ncl-button-icon-padding-small:var(--ncl-spacing-4);
  --ncl-button-icon-icon-size-small:var(--ncl-font-size-s);
  --ncl-button-icon-container-size-large:var(--ncl-spacing-42);
  --ncl-button-icon-padding-large:var(--ncl-spacing-8);
  --ncl-button-icon-icon-size-large:var(--ncl-font-size-2xl);
  --ncl-button-justify-content:center;
  --ncl-button-text-flex:auto;
  --ncl-checkbox-background-color:var(--ncl-color-neutral);
  --ncl-checkbox-background-color-checked:var(--ncl-color-neutral-80);
  --ncl-checkbox-width:var(--ncl-spacing-20);
  --ncl-checkbox-height:var(--ncl-spacing-20);
  --ncl-checkbox-border-radius:var(--ncl-border-radius-sharp);
  --ncl-checkbox-border:var(--ncl-spacing-1) solid var(--ncl-color-neutral-70);
  --ncl-checkbox-border-hover:var(--ncl-spacing-1) solid var(--ncl-color-primary);
  --ncl-checkbox-border-width-hover:var(--ncl-spacing-2);
  --ncl-checkbox-font-weight:var(--ncl-font-weight-regular);
  --ncl-checkbox-font-weight-hover:var(--ncl-font-weight-regular);
  --ncl-checkbox-font-weight-checked:var(--ncl-font-weight-regular);
  --ncl-checkbox-font-family:var(--ncl-font-family-primary);
  --ncl-checkbox-line-height:var(--ncl-letter-spacing-base);
  --ncl-checkbox-outline-focus:var(--ncl-focus-outline-size) solid var(--ncl-color-focus);
  --ncl-checkbox-outline-offset-focus:var(--ncl-focus-outline-offset);
  --ncl-checkbox-color-disabled:var(--ncl-color-neutral-70);
  --ncl-checkbox-border-disabled:var(--ncl-spacing-2) solid var(--ncl-color-disabled);
  --ncl-checkbox-font-size:var(--ncl-font-size-m);
  --ncl-checkbox-space-around:var(--ncl-spacing-0);
  --ncl-checkbox-font-color:var(--ncl-color-dark);
  --ncl-checkbox-label-font-color:var(--ncl-color-dark);
  --ncl-checkbox-text-position-x:var(--ncl-spacing-10);
  --ncl-checkbox-background-color-checked-disabled:var(--ncl-color-neutral-70);
  --ncl-checkbox-color-checked-disabled:var(--ncl-color-disabled);
  --ncl-checkbox-color-checked:var(--ncl-color-neutral);
  --ncl-collapsible-max-height:auto;
  --ncl-collapsible-transition-timing-function:linear;
  --ncl-collapsible-transition-delay:0s;
  --ncl-collapsible-transition-duration:0.2s;
  --ncl-collapsible-transition-property:max-height;
  --ncl-collapsible-fade-height:var(--ncl-spacing-35);
  --ncl-collapsible-fade-background:linear-gradient(to top, var(--ncl-color-neutral), transparent);
  --ncl-collapsible-controls-margin:var(--ncl-spacing-10) 0;
  --ncl-collapsible-controls-icon-position-left:var(--ncl-spacing-1);
  --ncl-collapsible-controls-icon-position-bottom:calc(var(--ncl-spacing-1) * -1);
  --ncl-color-tag-group-extra-color-width:var(--ncl-spacing-32);
  --ncl-color-tag-group-extra-color-height:var(--ncl-spacing-32);
  --ncl-color-tag-group-gap:var(--ncl-spacing-8);
  --ncl-color-tag-border-radius:var(--ncl-border-radius-circle);
  --ncl-color-tag-padding:var(--ncl-spacing-0);
  --ncl-color-tag-background:transparent;
  --ncl-color-tag-box-shadow:none;
  --ncl-color-tag-border:var(--ncl-spacing-1) solid transparent;
  --ncl-color-tag-transition:none;
  --ncl-color-tag-cursor-pointer:pointer;
  --ncl-color-tag-border-size:var(--ncl-spacing-8);
  --ncl-color-tag-border-outside-size:var(--ncl-spacing-42);
  --ncl-color-tag-border-checked:var(--ncl-spacing-1) solid var(--ncl-color-dark);
  --ncl-color-tag-border-outside-radius:var(--ncl-spacing-40);
  --ncl-color-tag-inner-border:var(--ncl-spacing-0-5) solid var(--ncl-color-neutral-40);
  --ncl-color-tag-disabled-size:calc(100% - var(--ncl-spacing-14));
  --ncl-color-tag-disabled-position-top:var(--ncl-spacing-8);
  --ncl-color-tag-disabled-position-left:var(--ncl-spacing-7);
  --ncl-color-tag-input-width:var(--ncl-spacing-44);
  --ncl-color-tag-input-height:var(--ncl-spacing-44);
  --ncl-color-tag-color-light:var(--ncl-color-neutral);
  --ncl-color-tag-readonly-border-color:rgba(0, 0, 0, 0.2);
  --ncl-color-tag-color-dark:var(--ncl-color-dark);
  --ncl-color-tag-size-xs:var(--ncl-spacing-22);
  --ncl-color-tag-icon-size-xs:var(--ncl-spacing-12);
  --ncl-color-tag-size-s:var(--ncl-spacing-28);
  --ncl-color-tag-icon-size-s:var(--ncl-spacing-16);
  --ncl-color-tag-size-m:var(--ncl-spacing-36);
  --ncl-color-tag-icon-size-m:var(--ncl-spacing-18);
  --ncl-color-tag-size-l:var(--ncl-spacing-42);
  --ncl-color-tag-icon-size-l:var(--ncl-spacing-28);
  --ncl-color-tag-size-xl:var(--ncl-spacing-48);
  --ncl-color-tag-icon-size-xl:var(--ncl-spacing-36);
  --ncl-color-tag-border-size-checked:var(--ncl-spacing-8);
  --ncl-color-tag-outline-offset:var(--ncl-spacing-3);
  --ncl-color-tag-outline:var(--ncl-focus-outline-size) solid var(--ncl-color-focus);
  --ncl-color-tag-focus-radius:var(--ncl-spacing-4);
  --ncl-color-tag-disabled-opacity:0.4;
  --ncl-color-tag-disabled-stroke-color:var(--ncl-color-dark);
  --ncl-color-tag-disabled-stroke-width:var(--ncl-spacing-2);
  --ncl-color-tag-loading-size:var(--ncl-spacing-34);
  --ncl-color-tag-loading-gap:var(--ncl-spacing-9);
  --ncl-container-background:none;
  --ncl-container-box-shadow:none;
  --ncl-container-border-radius:0;
  --ncl-container-padding:0;
  --ncl-container-margin:0;
  --ncl-container-border:none;
  --ncl-container-focus-outline:var(--ncl-focus-outline-size) solid var(--ncl-color-focus);
  --ncl-container-focus-background:none;
  --ncl-container-focus-outline-offset:var(--ncl-spacing-0);
  --ncl-grid-card-border:var(--ncl-spacing-1) solid var(--ncl-color-dark-50);
  --ncl-grid-card-padding:var(--ncl-spacing-12) var(--ncl-spacing-20);
  --ncl-grid-card-inside-border:var(--ncl-spacing-1) solid var(--ncl-color-dark-50);
  --ncl-grid-card-footer-border:var(--ncl-spacing-1) solid var(--ncl-color-dark-30);
  --ncl-grid-card-footer-padding:0;
  --ncl-grid-card-header-padding:0;
  --ncl-grid-card-header-overflow:hidden;
  --ncl-grid-card-border-radius:var(--ncl-border-radius-sharp);
  --ncl-grid-card-content-background-color:transparent;
  --ncl-list-card-background:var(--ncl-color-neutral);
  --ncl-list-card-height:auto;
  --ncl-icon-background:transparent;
  --ncl-icon-color:var(--ncl-color-dark);
  --ncl-icon-font-variant:normal;
  --ncl-icon-font-weight:var(--ncl-font-weight-regular);
  --ncl-icon-line-height:var(--ncl-line-height-base);
  --ncl-icon-focus-outline:var(--ncl-focus-outline-size) solid var(--ncl-color-focus);
  --ncl-icon-focus-outline-offset:var(--ncl-focus-outline-offset);
  --ncl-icon-focus-border-radius:var(--ncl-border-radius-base);
  --ncl-icon-rounded-padding:var(--ncl-spacing-7);
  --ncl-icon-rounded-padding-xs:var(--ncl-spacing-5);
  --ncl-icon-rounded-padding-s:var(--ncl-spacing-7);
  --ncl-icon-rounded-padding-m:var(--ncl-spacing-9);
  --ncl-icon-rounded-padding-l:var(--ncl-spacing-10);
  --ncl-icon-rounded-padding-xl:var(--ncl-spacing-12);
  --ncl-icon-rounded-border-radius:var(--ncl-border-radius-pill);
  --ncl-icon-font-size-xs:var(--ncl-font-size-xs);
  --ncl-icon-font-dimensions-xs:auto;
  --ncl-icon-font-size-s:var(--ncl-font-size-s);
  --ncl-icon-font-dimensions-s:auto;
  --ncl-icon-font-size-m:var(--ncl-font-size-base);
  --ncl-icon-font-dimensions-m:auto;
  --ncl-icon-font-size-l:var(--ncl-font-size-xl);
  --ncl-icon-font-dimensions-l:auto;
  --ncl-icon-font-size-xl:var(--ncl-font-size-2xl);
  --ncl-icon-font-dimensions-xl:auto;
  --ncl-icon-color-variant-primary:var(--ncl-color-primary);
  --ncl-icon-color-variant-primary-filled:var(--ncl-color-dark);
  --ncl-icon-background-variant-primary-filled:var(--ncl-color-primary);
  --ncl-icon-color-variant-secondary:var(--ncl-color-secondary);
  --ncl-icon-color-variant-secondary-filled:var(--ncl-color-dark);
  --ncl-icon-background-variant-secondary-filled:var(--ncl-color-secondary);
  --ncl-icon-color-variant-tertiary:var(--ncl-color-tertiary);
  --ncl-icon-color-variant-tertiary-filled:var(--ncl-color-neutral);
  --ncl-icon-background-variant-tertiary-filled:var(--ncl-color-tertiary);
  --ncl-icon-color-variant-danger:var(--ncl-color-danger);
  --ncl-icon-color-variant-danger-filled:var(--ncl-color-neutral);
  --ncl-icon-background-variant-danger-filled:var(--ncl-color-danger);
  --ncl-icon-color-variant-warning:var(--ncl-color-warning);
  --ncl-icon-color-variant-warning-filled:var(--ncl-color-neutral);
  --ncl-icon-background-variant-warning-filled:var(--ncl-color-warning);
  --ncl-icon-color-variant-success:var(--ncl-color-success);
  --ncl-icon-color-variant-success-filled:var(--ncl-color-neutral);
  --ncl-icon-background-variant-success-filled:var(--ncl-color-success);
  --ncl-icon-color-variant-info:var(--ncl-color-info);
  --ncl-icon-color-variant-info-filled:var(--ncl-color-neutral);
  --ncl-icon-background-variant-info-filled:var(--ncl-color-info);
  --ncl-icon-color-variant-neutral:var(--ncl-color-neutral);
  --ncl-icon-color-variant-neutral-filled:var(--ncl-color-dark);
  --ncl-icon-background-variant-neutral-filled:var(--ncl-color-neutral);
  --ncl-icon-outline-background:transparent;
  --ncl-icon-outline-border:var(--ncl-spacing-1) solid var(--ncl-color-dark);
  --ncl-icon-outline-border-primary:var(--ncl-spacing-1) solid var(--ncl-color-primary);
  --ncl-icon-outline-border-secondary:var(--ncl-spacing-1) solid var(--ncl-color-secondary);
  --ncl-icon-outline-border-tertiary:var(--ncl-spacing-1) solid var(--ncl-color-tertiary);
  --ncl-icon-outline-border-neutral:var(--ncl-spacing-1) solid var(--ncl-color-neutral);
  --ncl-icon-outline-border-danger:var(--ncl-spacing-1) solid var(--ncl-color-danger);
  --ncl-icon-outline-border-warning:var(--ncl-spacing-1) solid var(--ncl-color-warning);
  --ncl-icon-outline-border-success:var(--ncl-spacing-1) solid var(--ncl-color-success);
  --ncl-icon-outline-border-info:var(--ncl-spacing-1) solid var(--ncl-color-info);
  --ncl-icon-size:2rem;
  --ncl-responsive-image-host-display:block;
  --ncl-skeleton-loading-min-height:var(--ncl-spacing-10);
  --ncl-skeleton-loading-background-color:#ccc;
  --ncl-tab-panel-padding:var(--ncl-spacing-24);
  --ncl-tab-panel-font-family:var(--ncl-font-family-primary);
  --ncl-tag-display:inline-block;
  --ncl-tag-font-family:var(--ncl-font-family-primary);
  --ncl-tag-wrapper-align-item:center;
  --ncl-tag-gap:var(--ncl-spacing-5);
  --ncl-tag-height-xsmall:var(--ncl-spacing-21);
  --ncl-tag-height-small:var(--ncl-spacing-25);
  --ncl-tag-height-medium:var(--ncl-spacing-30);
  --ncl-tag-height-large:var(--ncl-spacing-36);
  --ncl-tag-height-xlarge:var(--ncl-spacing-40);
  --ncl-tag-line-height:110%;
  --ncl-tag-primary-border-radius:var(--ncl-spacing-5);
  --ncl-tag-primary-background-color:#eaeaea;
  --ncl-tag-primary-font-color:var(--ncl-color-dark);
  --ncl-tag-primary-icon-color:var(--ncl-color-dark);
  --ncl-tag-primary-padding:0 var(--ncl-spacing-7);
  --ncl-tag-primary-xsmall-padding:0 var(--ncl-spacing-7);
  --ncl-tag-primary-small-padding:0 var(--ncl-spacing-7);
  --ncl-tag-primary-large-padding:0 var(--ncl-spacing-12);
  --ncl-tag-primary-xlarge-padding:0 var(--ncl-spacing-12);
  --ncl-tag-secondary-border-radius:var(--ncl-spacing-30);
  --ncl-tag-secondary-background-color:var(--ncl-color-dark);
  --ncl-tag-secondary-font-color:var(--ncl-color-neutral);
  --ncl-tag-secondary-icon-color:var(--ncl-color-neutral);
  --ncl-tag-secondary-padding:0 var(--ncl-spacing-9);
  --ncl-tag-secondary-xsmall-padding:0 var(--ncl-spacing-8);
  --ncl-tag-secondary-small-padding:0 var(--ncl-spacing-8);
  --ncl-tag-secondary-large-padding:0 var(--ncl-spacing-11);
  --ncl-tag-secondary-xlarge-padding:0 var(--ncl-spacing-13);
  --ncl-tag-tertiary-font-color:var(--ncl-color-dark);
  --ncl-tag-tertiary-icon-color:var(--ncl-color-dark);
  --ncl-tag-tertiary-background-color:var(--ncl-color-neutral);
  --ncl-tag-slot-position:calc(-1 * var(--ncl-spacing-2));
  --ncl-tag-slot-xsmall-position:calc(-1 * var(--ncl-spacing-2));
  --ncl-tag-slot-small-position:calc(-1 * var(--ncl-spacing-2));
  --ncl-tag-slot-large-position:calc(-1 * var(--ncl-spacing-2));
  --ncl-tag-slot-xlarge-position:calc(-1 * var(--ncl-spacing-3));
  --ncl-tag-content-overflow:hidden;
  --ncl-tag-content-white-space:normal;
  --ncl-tab-header-display:inline-flex;
  --ncl-tab-header-flex-direction:unset;
  --ncl-tab-header-primary-border-radius:var(--ncl-spacing-40);
  --ncl-tab-header-primary-border:var(--ncl-spacing-1) solid var(--ncl-color-neutral-20);
  --ncl-tab-header-primary-background:var(--ncl-color-neutral);
  --ncl-tab-header-primary-padding:var(--ncl-spacing-8);
  --ncl-tab-header-container-padding:var(--ncl-spacing-12) var(--ncl-spacing-0);
  --ncl-tab-header-container-border-bottom:var(--ncl-spacing-1) solid var(--ncl-color-dark);
  --ncl-tab-header-width:100%;
  --ncl-tab-header-font-family:var(--ncl-font-family-primary);
  --ncl-tab-header-min-width:var(--ncl-spacing-80);
  --ncl-tab-header-text-align:center;
  --ncl-tab-header-font-size:var(--ncl-font-size-l);
  --ncl-tab-header-line-height:var(--ncl-line-height-m);
  --ncl-tab-header-font-weight:var(--ncl-font-weight-regular);
  --ncl-tab-header-focus-outline:var(--ncl-focus-outline-size) solid var(--ncl-color-focus);
  --ncl-tab-header-outline-offset:var(--ncl-focus-outline-offset);
  --ncl-tab-header-selected-font-weight:var(--ncl-font-weight-bold);
  --ncl-tab-header-hover-font-weight:var(--ncl-font-weight-bold);
  --ncl-tab-header-indicator-selected-width:100%;
  --ncl-tab-header-indicator-selected-height:var(--ncl-spacing-3);
  --ncl-tab-header-indicator-selected-color:var(--ncl-color-warning);
  --ncl-tab-header-indicator-selected-left:var(--ncl-spacing-0);
  --ncl-tab-header-indicator-selected-bottom:calc(var(--ncl-spacing-11) * -1 - var(--ncl-spacing-1));
  --ncl-tab-header-color-disabled:var(--ncl-color-disabled);
  --ncl-tab-header-indicator-font-weight:var(--ncl-font-weight-bold);
  --ncl-tab-header-indicator-selected-content:"";
  --ncl-tab-header-secondary-content:"";
  --ncl-tab-header-tertiary-size:1.5rem;
  --ncl-tab-header-tertiary-color:var(--ncl-tab-primary-background);
  --ncl-tab-header-tertiary-padding-vertical:2rem;
  --ncl-tab-header-tertiary-padding-horizontal:2rem;
  --ncl-tab-header-tertiary-border-width:0.15rem;
  --ncl-tab-header-tertiary-border-style:solid;
  --ncl-tab-header-tertiary-justify-content:start;
  --ncl-tab-header-tertiary-height-nav:100%;
  --ncl-tab-primary-border:none;
  --ncl-tab-primary-border-radius:var(--ncl-spacing-40);
  --ncl-tab-primary-padding:var(--ncl-spacing-8);
  --ncl-tab-primary-gap:0;
  --ncl-tab-primary-text-transform:uppercase;
  --ncl-tab-primary-font-size:var(--ncl-font-size-l);
  --ncl-tab-primary-line-height:var(--ncl-spacing-24);
  --ncl-tab-primary-background:var(--ncl-color-dark);
  --ncl-tab-primary-color:var(--ncl-color-neutral);
  --ncl-tab-secondary-color:var(--ncl-color-dark);
  --ncl-tab-secondary-background:var(--ncl-color-dark);
  --ncl-tab-headers-width:100%;
  --ncl-tab-container-content:"";
  --ncl-tab-panels-width:100%;
  --ncl-tab-panels-overflow:auto;
  --ncl-tab-height:100%;
  --ncl-tab-display:block;
  --ncl-tooltip-outline-width-focus:0px;
  --ncl-tooltip-outline-style-focus:solid;
  --ncl-tooltip-outline-color-focus:var(--ncl-color-focus);
  --ncl-tooltip-z-index:var(--ncl-z-index-high);
  --ncl-tooltip-background-color:var(--ncl-color-neutral);
  --ncl-tooltip-padding:var(--ncl-spacing-16) var(--ncl-spacing-30) var(--ncl-spacing-16) var(--ncl-spacing-16);
  --ncl-tooltip-border-radius:var(--ncl-border-radius-base);
  --ncl-tooltip-box-shadow:0 2px 20px 0 rgba(0, 0, 0, 0.2);
  --ncl-tooltip-box-shadow-focus:1px 1px 0 var(--ncl-color-dark-20);
  --ncl-tooltip-arrow-box-shadow-focus:1px 1px 0 var(--ncl-color-dark-20);
  --ncl-tooltip-primary-background-color:var(--ncl-color-neutral);
  --ncl-tooltip-arrow-primary-background-color:var(--ncl-color-neutral);
  --ncl-tooltip-secondary-background-color:var(--ncl-color-dark-5);
  --ncl-tooltip-arrow-secondary-background-color:var(--ncl-color-dark-5);
  --ncl-tooltip-secondary-font-color:var(--ncl-color-neutral);
  --ncl-tooltip-secondary-icon-color:var(--ncl-color-neutral);
  --ncl-tooltip-content-padding:0 0.5rem 0 0;
  --ncl-tooltip-close-button-size:1.2rem;
  --ncl-tooltip-close-button-padding:0.8rem;
  --ncl-tooltip-close-button-top:0;
  --ncl-tooltip-close-button-right:0;
  --ncl-tooltip-close-button-left:auto;
  --ncl-tooltip-close-button-cursor:pointer;
  --ncl-tooltip-close-button-outline-color:var(--ncl-color-focus);
  --ncl-tooltip-close-button-outline-width:var(--ncl-spacing-2);
  --ncl-tooltip-close-button-outline-offset:0px;
  --ncl-tooltip-close-button-outline-border-radius:var(--ncl-spacing-8);
  --ncl-tooltip-font:var(--ncl-font-weight-regular) var(--ncl-font-size-s)/var(--ncl-line-height-s) var(--ncl-font-family-primary);
  --ncl-tooltip-font-color:var(--ncl-font-color-primary);
  --ncl-tooltip-arrow-box-shadow:1px 1px 0px rgba(0, 0, 0, 0.03);
  --ncl-tooltip-arrow-width:var(--ncl-spacing-12);
  --ncl-tooltip-arrow-height:var(--ncl-spacing-12);
  --ncl-tooltip-trigger-display:flex;
  --ncl-tooltip-trigger-flex-justify-content:center;
  --ncl-tooltip-trigger-flex-align-items:center;
  --ncl-tooltip-trigger-outline-color:var(--ncl-color-focus);
  --ncl-tooltip-trigger-outline-width:var(--ncl-spacing-1);
  --ncl-tooltip-trigger-outline-offset:0px;
  --ncl-tooltip-trigger-border-radius:var(--ncl-spacing-0);
  --ncl-tooltip-arrow-bottom-translate:0px -1px;
  --ncl-tooltip-arrow-top-translate:0px 1px;
  --ncl-tooltip-arrow-right-translate:-1px 0px;
  --ncl-tooltip-arrow-left-translate:1px 0px;
  --ncl-tooltip-scrollbar-size:0.8rem;
  --ncl-tooltip-scrollbar-track-border-radius:var(--ncl-spacing-10);
  --ncl-tooltip-scrollbar-track-background-color:var(--ncl-color-dark-50);
  --ncl-tooltip-scrollbar-thumb-border-radius:var(--ncl-spacing-10);
  --ncl-tooltip-scrollbar-thumb-background-color:var(--ncl-color-dark-5);
  --ncl-tooltip-scrollbar-thumb-border:var(--ncl-spacing-1) solid var(--ncl-color-neutral);
  --ncl-text-font:var(--ncl-font-weight-regular) var(--ncl-font-size-s)/var(--ncl-line-height-s) var(--ncl-font-family-primary);
  --ncl-text-decoration-style:solid;
  --ncl-text-decoration-thickness:var(--ncl-spacing-1);
  --ncl-text-decoration-offset:auto;
  --ncl-text-color-primary:var(--ncl-color-primary);
  --ncl-text-color-secondary:var(--ncl-color-secondary);
  --ncl-text-color-tertiary:var(--ncl-color-tertiary);
  --ncl-text-color-dark:var(--ncl-color-dark);
  --ncl-text-color-neutral:var(--ncl-color-neutral);
  --ncl-text-color-danger:var(--ncl-color-danger);
  --ncl-text-color-warning:var(--ncl-color-warning);
  --ncl-text-color-success:var(--ncl-color-success);
  --ncl-text-color-info:var(--ncl-color-info);
  --ncl-select-gap:var(--ncl-spacing-15);
  --ncl-select-max-width:none;
  --ncl-select-min-width:auto;
  --ncl-select-width:auto;
  --ncl-select-label-font-weight:var(--ncl-font-weight-medium);
  --ncl-select-label-medium-font-size:var(--ncl-font-size-m);
  --ncl-select-label-line-height:var(--ncl-line-height-s);
  --ncl-select-label-font-family:var(--ncl-font-family-primary);
  --ncl-select-label-color:var(--ncl-color-dark);
  --ncl-select-label-position-side-template-columns:1fr 3fr;
  --ncl-select-font-family:var(--ncl-font-family-primary);
  --ncl-select-font-size:var(--ncl-font-size-m);
  --ncl-select-font-weight:var(--ncl-font-weight-regular);
  --ncl-select-line-height:var(--ncl-line-height-s);
  --ncl-select-color:var(--ncl-color-dark);
  --ncl-select-padding:var(--ncl-spacing-16) var(--ncl-spacing-40) var(--ncl-spacing-16) var(--ncl-spacing-18);
  --ncl-select-background-color:var(--ncl-color-neutral);
  --ncl-select-background-image:url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7 7L1 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
  --ncl-select-icon-right-distance:var(--ncl-spacing-20);
  --ncl-select-background-size:var(--ncl-spacing-14);
  --ncl-select-border-radius:var(--ncl-border-radius-base);
  --ncl-select-default-border:var(--ncl-spacing-1) solid var(--stla-foreground-color-secondary);
  --ncl-select-outline-focus:var(--ncl-focus-outline-size) solid var(--ncl-color-focus);
  --ncl-select-outline-offset:var(--ncl-focus-outline-offset);
  --ncl-select-background-color-hover:var(--ncl-color-neutral-background);
  --ncl-select-custom-glyph-size:var(--ncl-spacing-17);
  --ncl-select-small-custom-glyph-size:var(--ncl-spacing-14);
  --ncl-select-large-custom-glyph-size:var(--ncl-spacing-20);
  --ncl-select-disabled-color:var(--ncl-color-disabled);
  --ncl-select-background-image-disabled:url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7 7L1 1' stroke='%23aaaaaa' stroke-width='2'/%3E%3C/svg%3E");
  --ncl-select-inverted-border:var(--ncl-spacing-1) solid var(--ncl-color-neutral);
  --ncl-select-inverted-color:var(--ncl-color-neutral);
  --ncl-select-inverted-background-color:transparent;
  --ncl-select-inverted-background-image:url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7 7L1 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
  --ncl-select-inverted-background-hover:var(--ncl-color-neutral-60);
  --ncl-select-inverted-label-color:var(--ncl-color-neutral);
  --ncl-select-inverted-custom-glyph-color:var(--ncl-color-neutral);
  --ncl-select-invalid-custom-glyph-color:var(--ncl-color-dark);
  --ncl-select-outline-background-color:transparent;
  --ncl-select-outline-background-image:url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7 7L1 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
  --ncl-select-outline-invalid-background-color:transparent;
  --ncl-select-small-padding:var(--ncl-spacing-10) var(--ncl-spacing-18);
  --ncl-select-small-font-size:var(--ncl-font-size-s);
  --ncl-select-small-line-height:var(--ncl-line-height-s);
  --ncl-select-small-background-size:var(--ncl-spacing-12) auto;
  --ncl-select-large-padding:var(--ncl-spacing-20) var(--ncl-spacing-18);
  --ncl-select-large-font-size:var(--ncl-font-size-l);
  --ncl-select-large-line-height:var(--ncl-line-height-s);
  --ncl-select-large-background-size:var(--ncl-spacing-16) auto;
  --ncl-select-invalid-label-color:var(--ncl-color-danger);
  --ncl-select-invalid-border:var(--ncl-spacing-1) solid var(--ncl-color-danger);
  --ncl-select-invalid-color:var(--ncl-color-danger);
  --ncl-select-invalid-background-color:var(--ncl-color-danger-10);
  --ncl-select-invalid-background-image:url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7 7L1 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
  --ncl-select-inverted-invalid-background-image:url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7 7L1 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
  --ncl-dropdown-display:inline-block;
  --ncl-dropdown-trigger-icon-margin:0 0 0 0.5rem;
  --ncl-dropdown-trigger-border-bottom:0;
  --ncl-dropdown-trigger-display:flex;
  --ncl-dropdown-label-display:block;
  --ncl-dropdown-label-font-size:var(--ncl-font-size-l);
  --ncl-dropdown-label-margin:0 0 0.8rem 0;
  --ncl-dropdown-list-padding:0.8rem;
  --ncl-dropdown-list-right:0;
  --ncl-dropdown-list-left:left;
  --ncl-dropdown-list-spacing:0.8rem;
  --ncl-dropdown-list-top:calc(100% + 0.8rem);
  --ncl-dropdown-list-bottom:auto;
  --ncl-dropdown-list-box-shadow:0 0 2rem var(--ncl-color-dark-20);
  --ncl-dropdown-list-border-radius:0.5rem;
  --ncl-dropdown-list-z-index:1;
  --ncl-dropdown-list-animation-duration:250ms;
  --ncl-dropdown-primary-trigger-border:1px solid var(--ncl-color-dark-50);
  --ncl-dropdown-primary-trigger-border-radius:0.4rem;
  --ncl-dropdown-primary-trigger-padding:1.2rem 1.6rem;
  --ncl-dropdown-primary-list-width:100%;
  --ncl-dropdown-outline-width:var(--ncl-spacing-2);
  --ncl-dropdown-outline-color:var(--ncl-color-focus);
  --ncl-dropdown-background-color:none;
  --ncl-dropdown-item-selected-background:var(--ncl-color-dark-60);
  --ncl-dropdown-item-hover-background:var(--ncl-color-dark-80);
  --ncl-dropdown-item-border-radius:0.5rem;
  --ncl-dropdown-item-padding:0.2rem;
  --ncl-dropdown-item-margin:0.2rem 0;
  --ncl-input-gap:var(--ncl-spacing-15);
  --ncl-input-min-width:var(--ncl-spacing-185);
  --ncl-input-font-family:var(--ncl-font-family-primary);
  --ncl-input-font-size:var(--ncl-font-size-l);
  --ncl-input-prefix-left:var(--ncl-spacing-14);
  --ncl-input-prefix-color:var(--ncl-color-dark);
  --ncl-input-prefix-z-index:1;
  --ncl-input-prefix-padding-left:var(--ncl-spacing-26);
  --ncl-input-label-font-family:var(--ncl-font-family-primary);
  --ncl-input-label-color:var(--ncl-color-dark-50);
  --ncl-input-label-font-weight:var(--ncl-font-weight-medium);
  --ncl-input-label-font-size:var(--ncl-font-size-m);
  --ncl-input-suffix-right:var(--ncl-spacing-10);
  --ncl-input-suffix-index:1;
  --ncl-input-suffix-padding:var(--ncl-spacing-4);
  --ncl-input-clear-color:var(--ncl-color-dark);
  --ncl-input-clear-background:transparent;
  --ncl-input-clear-border:var(--ncl-spacing-1) solid transparent;
  --ncl-input-date-icon-padding:var(--ncl-spacing-4);
  --ncl-input-date-icon-right:var(--ncl-spacing-45);
  --ncl-input-number-icon-visibility:visible;
  --ncl-input-number-icon-top:28%;
  --ncl-input-number-icon-right:var(--ncl-spacing-45);
  --ncl-input-number-icon-padding:var(--ncl-spacing-4);
  --ncl-input-number-icon-height:var(--ncl-spacing-15);
  --ncl-input-font-size-xsmall:var(--ncl-font-size-xs);
  --ncl-input-padding-xsmall:var(--ncl-spacing-10) var(--ncl-spacing-6);
  --ncl-input-font-size-small:var(--ncl-font-size-s);
  --ncl-input-padding-small:var(--ncl-spacing-12) var(--ncl-spacing-8);
  --ncl-input-font-size-large:var(--ncl-font-size-m);
  --ncl-input-padding-large:var(--ncl-spacing-20) var(--ncl-spacing-12);
  --ncl-input-font-size-xlarge:var(--ncl-font-size-l);
  --ncl-input-padding-xlarge:var(--ncl-spacing-24) var(--ncl-spacing-14);
  --ncl-input-disabled-color:var(--ncl-color-disabled);
  --ncl-input-disabled-background:var(--ncl-color-neutral-10);
  --ncl-input-disabled-label-color:var(--ncl-color-disabled);
  --ncl-input-disabled-clear-color:var(--ncl-color-disabled);
  --ncl-input-invalid-color:var(--ncl-color-danger);
  --ncl-input-invalid-background:var(--ncl-color-danger-10);
  --ncl-input-invalid-border:var(--ncl-spacing-1) solid var(--ncl-color-danger);
  --ncl-input-invalid-label-color:var(--ncl-color-danger);
  --ncl-input-invalid-clear-color:var(--ncl-color-danger);
  --ncl-input-focus-outline-width:var(--ncl-focus-outline-size);
  --ncl-input-focus-outline-color:var(--ncl-color-focus);
  --ncl-input-focus-outline-offset:var(--ncl-focus-outline-offset);
  --ncl-input-clear-button-outline-offset:var(--ncl-spacing-2);
  --ncl-input-clear-button-outline-width:var(--ncl-focus-outline-size);
  --ncl-input-clear-button-outline-color:var(--ncl-color-focus);
  --ncl-input-clear-button-focus-border-radius:var(--ncl-border-radius-base);
  --ncl-modal-max-height:calc(100vh - 10rem);
  --ncl-modal-min-height:35.6rem;
  --ncl-modal-border-radius:var(--ncl-border-radius-sharp);
  --ncl-modal-box-shadow:none;
  --ncl-modal-background:var(--ncl-color-neutral);
  --ncl-modal-width:100vw;
  --ncl-modal-max-width:65rem;
  --ncl-modal-border:none;
  --ncl-modal-section-border:var(--ncl-spacing-1) solid var(--ncl-color-neutral-20);
  --ncl-modal-heading-box-shadow:none;
  --ncl-modal-heading-padding:var(--ncl-spacing-9) var(--ncl-spacing-10);
  --ncl-modal-heading-background:var(--ncl-color-neutral);
  --ncl-modal-heading-min-height:var(--ncl-spacing-31);
  --ncl-modal-heading-wrapper-flex-direction:row-reverse;
  --ncl-modal-heading-wrapper-border-radius:0;
  --ncl-modal-heading-wrapper-height:auto;
  --ncl-modal-actions-box-shadow:none;
  --ncl-modal-actions-padding:0 var(--ncl-spacing-20);
  --ncl-modal-actions-padding-mobile:var(--ncl-spacing-20);
  --ncl-modal-actions-background:var(--ncl-color-neutral);
  --ncl-modal-actions-min-height:var(--ncl-spacing-84);
  --ncl-modal-actions-min-height-mobile:var(--ncl-spacing-96);
  --ncl-modal-drawer-border:none;
  --ncl-modal-drawer-background-color:var(--ncl-color-neutral-background);
  --ncl-modal-drawer-width:100vw;
  --ncl-modal-drawer-max-width:375px;
  --ncl-modal-drawer-heading-border:none;
  --ncl-modal-drawer-actions-border:none;
  --ncl-modal-button-align-self:auto;
  --ncl-modal-button-bottom:0;
  --ncl-modal-button-size:var(--ncl-spacing-36);
  --ncl-modal-button-background:var(--ncl-color-neutral);
  --ncl-modal-button-border:var(--ncl-spacing-1) solid var(--ncl-color-dark);
  --ncl-modal-button-border-radius:var(--ncl-spacing-36);
  --ncl-modal-button-line-height:var(--ncl-spacing-12);
  --ncl-modal-close-button-icon-color:var(--ncl-color-dark);
  --ncl-modal-close-button-icon-size:1.2rem;
  --ncl-modal-z-index:50000;
  --ncl-modal-drawer-close-button-font-weight:var(--ncl-font-weight-medium);
  --ncl-modal-drawer-close-button-font-size:var(--ncl-font-size-m);
  --ncl-modal-drawer-heading-shadow-width:var(--ncl-spacing-35);
  --ncl-modal-drawer-heading-shadow-height:100%;
  --ncl-modal-drawer-heading-shadow-position:0;
  --ncl-modal-drawer-heading-shadow-background:linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 92%);
  --ncl-modal-drawer-heading-shadow-background-left:linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 92%);
  --ncl-modal-drawer-headings-max-height:var(--ncl-spacing-60);
  --ncl-modal-drawer-heading-shadow-z-index:1;
  --ncl-modal-drawer-close-button-radius:var(--ncl-spacing-3);
  --ncl-modal-drawer-close-button-margin:0 var(--ncl-spacing-1) 0 0;
  --ncl-modal-drawer-close-button-border-hover:var(--ncl-spacing-1) solid var(--ncl-color-dark-5);
  --ncl-modal-drawer-close-button-margin-left:var(--ncl-spacing-3);
  --ncl-modal-drawer-heading-padding:var(--ncl-spacing-5);
  --ncl-modal-drawer-content-padding:var(--ncl-spacing-40) var(--ncl-spacing-20);
  --ncl-modal-drawer-desktop-content-padding:var(--ncl-spacing-40) var(--ncl-spacing-60);
  --ncl-modal-backdrop-opacity:0;
  --ncl-modal-backdrop-background:rgba(0, 0, 0, 0.8);
  --ncl-modal-backdrop-transition:all 0.5s var(--ncl-transition-effect) 0s;
  --ncl-modal-window-transition:all 0.5s var(--ncl-transition-effect) 0s;
  --ncl-modal-window-opacity:0;
  --ncl-modal-window-transform:translate(0, 50%);
  --ncl-modal-drawer-window-transform:translate(100%, 0);
  --ncl-modal-drawer-window-opacity:0;
  --ncl-modal-open-backdrop-opacity:1;
  --ncl-modal-open-window-transform:translate(0, 0);
  --ncl-modal-open-window-opacity:1;
  --ncl-modal-open-window-overflow-y:auto;
  --ncl-modal-drawer-open-window-transform:translate(0, 0);
  --ncl-modal-drawer-open-window-opacity:1;
  --ncl-modal-button-color-disabled:var(--ncl-color-disabled);
  --ncl-modal-button-icon-color-disabled:var(--ncl-color-disabled);
  --ncl-modal-button-border-color-disabled:var(--ncl-color-disabled);
  --ncl-drawer-heading-box-shadow:0 0.8rem 4rem rgba(0, 0, 0, 0.08);
  --ncl-drawer-actions-box-shadow:0 -0.8rem 4rem rgba(0, 0, 0, 0.08);
  --ncl-drawer-close-button-height:var(--ncl-spacing-16);
  --ncl-drawer-desktop-max-width:76.8rem;
  --ncl-drawer-dialog-z-index:99999;
  --ncl-drawer-close-button-z-index:99999;
  --ncl-range-slider-flex-direction:column;
  --ncl-range-slider-gap:var(--ncl-spacing-24);
  --ncl-range-slider-color-disabled:var(--ncl-color-neutral-50);
  --ncl-range-slider-fill-lower-disabled:var(--ncl-color-neutral-50);
  --ncl-range-slider-fill-upper:var(--ncl-color-dark-30);
  --ncl-range-slider-thumb-border-disabled:var(--ncl-spacing-2) solid var(--ncl-color-neutral-50);
  --ncl-range-slider-thumb-scale-default:1;
  --ncl-range-slider-input-position-focus:calc(var(--ncl-spacing-2) * -1);
  --ncl-range-slider-input-width-focus:calc(100% + var(--ncl-spacing-8));
  --ncl-range-slider-input-height-focus:calc(100% + var(--ncl-spacing-4));
  --ncl-range-slider-input-radius-focus:var(--ncl-spacing-4);
  --ncl-range-slider-input-outline-focus:var(--ncl-focus-outline-size) solid var(--ncl-color-focus);
  --ncl-range-slider-input-z-index-focus:-1;
  --ncl-range-slider-thumb-scale-hover:1.1;
  --ncl-range-slider-thumb-focus-visible-outline:var(--ncl-spacing-2) solid var(--ncl-color-focus);
  --ncl-range-slider-thumb-focus-visible-outline-offset:var(--ncl-spacing-2);
  --ncl-range-slider-thumb-height:var(--ncl-spacing-28);
  --ncl-range-slider-thumb-width:var(--ncl-spacing-28);
  --ncl-range-slider-thumb-border:var(--ncl-spacing-2) solid var(--ncl-color-dark);
  --ncl-range-slider-thumb-border-radius:var(--ncl-border-radius-circle);
  --ncl-range-slider-thumb-background-color:var(--ncl-color-neutral);
  --ncl-range-slider-thumb-box-shadow:0 var(--ncl-spacing-5) var(--ncl-spacing-12) rgba(0, 0, 0, 0.1);
  --ncl-range-slider-thumb-transition:all 0.2s var(--ncl-transition-effect) 0s;
  --ncl-range-slider-track-height:var(--ncl-spacing-8);
  --ncl-range-slider-track-border-radius:var(--ncl-border-radius-pill);
  --ncl-range-slider-fill-lower:var(--ncl-color-primary);
  --ncl-range-slider-fill-lower-focus:var(--ncl-color-primary);
  --ncl-range-slider-fill-upper-focus:var(--ncl-color-dark-30);
  --ncl-range-slider-label-font-family:var(--ncl-font-family-primary);
  --ncl-range-slider-label-font-weight:var(--ncl-font-weight-bold);
  --ncl-range-slider-label-font-size:var(--ncl-font-size-m);
  --ncl-range-slider-output-background:var(--ncl-color-neutral);
  --ncl-range-slider-output-border:var(--ncl-spacing-1) solid var(--ncl-color-dark-50);
  --ncl-range-slider-output-color:var(--ncl-color-dark);
  --ncl-range-slider-output-border-radius:var(--ncl-border-radius-base);
  --ncl-range-slider-output-font-family:var(--ncl-font-family-primary);
  --ncl-range-slider-output-font-weight:var(--ncl-font-weight-medium);
  --ncl-range-slider-output-font-size:var(--ncl-font-size-m);
  --ncl-range-slider-output-padding:var(--ncl-spacing-5) var(--ncl-spacing-9);
  --ncl-range-slider-output-box-shadow:0 var(--ncl-spacing-5) var(--ncl-spacing-12) rgba(0, 0, 0, 0.1);
  --ncl-range-slider-output-position-top:var(--ncl-spacing-26);
  --ncl-range-slider-output-position-top-moz:var(--ncl-spacing-33);
  --ncl-range-slider-output-min-width:max-content;
  --ncl-range-slider-output-max-width:auto;
  --ncl-range-slider-position-top-moz:var(--ncl-spacing-28);
  --ncl-animated-hero-dark-gradient-top:linear-gradient(180deg, rgba(0, 0, 0, 0.8) 7.1%, rgba(0, 0, 0, 0.4) 70.16%, rgba(0, 0, 0, 0) 100%);
  --ncl-animated-hero-dark-gradient-bottom:linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.3) 33.94%, rgba(0, 0, 0, 0) 66.07%);
  --ncl-animated-hero-min-height:35rem;
  --ncl-animated-hero-min-width:100%;
  --ncl-animated-hero-background-color:var(--ncl-color-dark-5);
  --ncl-animated-hero-outline-color:var(--ncl-color-neutral);
  --ncl-animated-hero-title-container-padding-top:5.2rem;
  --ncl-animated-hero-title-container-padding-left:1.5rem;
  --ncl-animated-hero-title-container-padding-right:5%;
  --ncl-animated-hero-title-container-row-gap:1.2rem;
  --ncl-animated-hero-title-container-max-width:144rem;
  --ncl-animated-hero-sup-title-font-size:1.6rem;
  --ncl-animated-hero-sup-title-line-height:1.2;
  --ncl-animated-hero-sup-title-font-weight:var(--ncl-font-weight-medium);
  --ncl-animated-hero-sup-title-padding:0 0.6rem;
  --ncl-animated-hero-title-font-size:2.4rem;
  --ncl-animated-hero-title-line-height:1.33;
  --ncl-animated-hero-title-font-weight:var(--ncl-font-weight-medium);
  --ncl-animated-hero-title-column-gap:1rem;
  --ncl-animated-hero-selector-button-container-min-width:50%;
  --ncl-animated-hero-selector-button-container-padding:0.2rem;
  --ncl-animated-hero-selector-button-item-font-weight:var(--ncl-font-weight-bold);
  --ncl-animated-hero-selector-button-item-text-underline-offset:0.5rem;
  --ncl-animated-hero-selector-button-item-column-gap:0.8rem;
  --ncl-animated-hero-selector-button-icon-size:3.2rem;
  --ncl-animated-hero-selector-button-suffix-padding:0 0 0 0.8rem;
  --ncl-animated-hero-jelly-container-width:100%;
  --ncl-animated-hero-jelly-container-height:auto;
  --ncl-animated-hero-jelly-container-z-index:1;
  --ncl-animated-hero-jelly-container-outline-offset:0;
  --ncl-animated-hero-jelly-size:45%;
  --ncl-animated-hero-jelly-postition:4%;
  --ncl-animated-hero-jelly-margin-bottom:40%;
  --ncl-animated-hero-jelly-bottom:50%;
  --ncl-animated-hero-single-jelly-margin-bottom:27%;
  --ncl-animated-hero-single-jelly-size:85%;
  --ncl-animated-hero-single-jelly-right:unset;
  --ncl-animated-hero-jelly-name-font-size:1.4rem;
  --ncl-animated-hero-jelly-name-font-weight:var(--ncl-font-weight-medium);
  --ncl-animated-hero-jelly-name-line-height:1.2;
  --ncl-animated-hero-jelly-name-height:100%;
  --ncl-animated-hero-jelly-name-margin:1rem 0 0;
  --ncl-animated-hero-jelly-name-3-items-max-width:65%;
  --ncl-animated-hero-jelly-name-3-items-margin:5%;
  --ncl-animated-hero-jelly-middle-item-z-index:1;
  --ncl-animated-hero-jelly-middle-item-max-width:calc(var(--ncl-animated-hero-jelly-size) - 13%);
  --ncl-animated-hero-jelly-middle-item-margin-bottom:calc(var(--ncl-animated-hero-jelly-margin-bottom) - 5%);
  --ncl-flip-card-list-gap:2rem;
  --ncl-flip-card-list-background-dark:var(--ncl-color-neutral-60);
  --ncl-flip-card-border-radius:0.8rem;
  --ncl-flip-card-icon-size:12rem;
  --ncl-flip-card-title-color:var(--ncl-color-dark);
  --ncl-flip-card-title-color-dark:var(--ncl-color-primary);
  --ncl-flip-card-title-font-size:4.8rem;
  --ncl-flip-card-title-font-weight:var(--ncl-font-weight-regular);
  --ncl-flip-card-title-line-height:1;
  --ncl-flip-card-title-padding:2rem 0 0.8rem;
  --ncl-flip-card-title-text-transform:uppercase;
  --ncl-flip-card-subtitle-color:var(--ncl-color-dark);
  --ncl-flip-card-subtitle-font-size:2rem;
  --ncl-flip-card-subtitle-font-weight:var(--ncl-font-weight-medium);
  --ncl-flip-card-subtitle-line-height:1.2;
  --ncl-flip-card-subtitle-padding-bottom:2.4rem;
  --ncl-flip-card-description-color:var(--ncl-color-neutral);
  --ncl-flip-card-description-font-size:1.4rem;
  --ncl-flip-card-description-font-weight:var(--ncl-font-weight-medium);
  --ncl-flip-card-description-line-height:1.2;
  --ncl-flip-card-info-back-color:var(--ncl-color-neutral);
  --ncl-flip-card-info-back-font-size:1.4rem;
  --ncl-flip-card-info-back-font-weight:var(--ncl-font-weight-medium);
  --ncl-flip-card-info-back-line-height:1.2;
  --ncl-flip-card-flip-button-height:1.6rem;
  --ncl-flip-card-flip-button-icon-height:2.4rem;
  --ncl-flip-card-flip-button-container-min-height:2.4rem;
  --ncl-flip-card-border-dark:var(--ncl-color-dark);
  --ncl-sub-menu-alternate-background-color:var(--ncl-color-dark);
  --ncl-sub-menu-background-color:var(--ncl-color-neutral);
  --ncl-sub-menu-color:var(--ncl-color-dark);
  --ncl-sub-menu-icon-color:var(--ncl-color-dark);
  --ncl-sub-menu-icon-color-alternate:var(--ncl-color-dark);
  --ncl-sub-menu-item-border:unset;
  --ncl-sub-menu-item-border-dark:unset;
  --ncl-sub-menu-item-border-radius:unset;
  --ncl-sub-menu-item-border-top:0.1rem solid var(--static-element-stroke, var(--ncl-color-dark-50));
  --ncl-sub-menu-item-border-top-dark:0.1rem solid var(--static-element-stroke, var(--ncl-color-neutral-40));
  --ncl-sub-menu-item-flex-direction:row;
  --ncl-sub-menu-item-focus-outline:unset;
  --ncl-sub-menu-item-focus-outline-offset:unset;
  --ncl-sub-menu-item-justify-content:unset;
  --ncl-sub-menu-item-min-height:unset;
  --ncl-sub-menu-item-padding:1.6rem 3.2rem;
  --ncl-sub-menu-flex-direction:column;
  --ncl-sub-menu-gap:0;
  --ncl-sub-menu-item-icon-size:4.8rem;
  --ncl-sub-menu-item-gap:1rem;
  --ncl-sub-menu-item-background-hover-dark:var(--ncl-color-neutral-60);
  --ncl-nameplate-card-width:22.75rem;
  --ncl-nameplate-card-height:5.5rem;
  --ncl-nameplate-card-loading-height:20rem;
  --ncl-nameplate-card-loading-mask-image:--ncl-vehicle-card-loading-mask-image;
  --ncl-nameplate-card-padding:0.5rem;
  --ncl-nameplate-card-font-size-title:1.12rem;
  --ncl-nameplate-card-font-size-subtitle:1rem;
  --ncl-nameplate-card-font-size-near:0.87rem;
  --ncl-nameplate-card-gap:0.8rem;
  --ncl-nameplate-card-thumbnail-width:7.5rem;
  --ncl-nameplate-card-thumbnail-height:5rem;
  --ncl-nameplate-card-thumbnail-object-fit:cover;
  --ncl-nameplate-card-content-margin-left:1rem;
  --ncl-nameplate-card-title-width:9.12rem;
  --ncl-nameplate-card-subtitle-width:7.81rem;
  --ncl-nameplate-card-near-width:5.43rem;
  --ncl-nameplate-card-content-gap:1rem;
  --ncl-nameplate-card-title-max-width:18rem;
}
:root {
  --ncl-color-primary:#3b78b5;
  --ncl-color-primary-light:#273a74;
  --ncl-color-primary-dark:#273a74;
  --ncl-button-font-color:#fff;
  --ncl-button-primary-font-color:#fff;
  --ncl-button-primary-plain-color:#000;
  --ncl-button-primary-outline-color:#000;
  --ncl-button-inverted-primary-color:#000;
  --ncl-button-inverted-secondary-color:#000;
  --ncl-tab-header-indicator-selected-color:#3b78b5;
  --ncl-tab-secondary-color:#fff;
}

:root ncl-checkbox {
  --ncl-checkbox-checked-background-color:#3b78b5;
  --ncl-checkbox-font-color:#fff;
}